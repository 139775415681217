import { Flex, Box, Text, Divider, rem, Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { ActivityAnswer } from 'types/graphql'
import ActivityToCorrect from 'web/src/components/learning/ActivityToCorrect/ActivityToCorrect'

interface ExtendedWritingAnswer extends ActivityAnswer {
  lessons?: Array<{
    lessonTitle: string
    activities?: Array<{
      activityOrder: number
      teacherFeedbackAt: string | null
      unitId: number
      lessonId: number
      activityId: number
      latestUserAnswerAt: string | null
      latestAnswerType: string | null
    }>
  }>
}

type WritingListProps = {
  answers: ExtendedWritingAnswer[]
}

const WritingList = ({ answers }: WritingListProps) => {
  const { t } = useTranslation('learning')

  return (
    <Box>
      <Text
        size={rem(14)}
        pb={rem(16)}
        styles={{ root: { lineHeight: rem(18) } }}
      >
        {t('main.no-pending-activities-description')}
      </Text>
      {answers.map((answer, index) => (
        <Box key={index} data-sel="writing-item">
          <Flex align="center" my="md">
            <Divider w="100%" color="gray.4" />
            <Text
              style={{ flexGrow: 1, flexShrink: 0 }}
              size={rem(12)}
              px={rem(8)}
              data-sel="unit-title"
              c="cnaRed.6"
            >
              {answer.unitTitle}
            </Text>
            <Divider w="100%" color="gray.4" />
          </Flex>
          {answer.lessons?.length > 0 &&
            answer.lessons?.map((lesson, index) => (
              <Stack key={index} gap="md">
                {lesson.activities?.length > 0 &&
                  lesson.activities?.map((activity, index) => {
                    return (
                      <ActivityToCorrect
                        key={index}
                        activity={activity}
                        unitTitle={answer.unitTitle}
                        lessonTitle={lesson.lessonTitle}
                        to={`/learning/unit/${activity.unitId}/lessons/${activity.lessonId}/writing-activity/${activity.activityId}`}
                      />
                    )
                  })}
              </Stack>
            ))}
        </Box>
      ))}
    </Box>
  )
}

export default WritingList
