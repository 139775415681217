import { useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import store from 'store2'
import {
  ActivityAnswerField,
  Maybe,
  Module,
  SubModuleOnModule,
} from 'types/graphql'

import { routes, navigate, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { TypologyEnum } from 'src/enums/learning/activity/TypologyEnum'
import { GET_MODULE } from 'src/graphql/learning/learning'
import { getStoreLevel } from 'src/helpers/storage'
import { useActivity } from 'src/providers/learning/activity/ActivityProvider'

interface RandomSortableItem {
  id: number
  text: string
  coverMedia: unknown
  isCorrect?: boolean
}

type ModuleLessonsData = {
  FindModule?: Maybe<Module>
}

const randomSortable = (array: RandomSortableItem[]) => {
  if (array.length < 2) return array

  const shuffler = array.slice()

  for (let increment = shuffler.length - 1; increment > 0; increment--) {
    const random = Math.floor(Math.random() * (increment + 1))
    ;[shuffler[increment], shuffler[random]] = [
      shuffler[random],
      shuffler[increment],
    ]
  }

  return shuffler
}

const useRandomChoices = (answerFields: ActivityAnswerField[]) => {
  const choices = answerFields.flatMap((item) =>
    item.choices.map((choice) => ({
      id: choice.id,
      text: choice.text,
      coverMedia: choice.coverMedia,
      isCorrect: choice?.isCorrect,
    }))
  )
  return randomSortable(choices)
}
const useActivityTitle = (typology: string) => {
  const typologyTitle: { [key in TypologyEnum]: string } = {
    [TypologyEnum.SEQUENCING]: 'Sequencing',
    [TypologyEnum.MULTIPLE_CHOICE]: 'Multiple Choice',
    [TypologyEnum.BOOLEAN]: 'Boolean',
    [TypologyEnum.FILL_IN_THE_BLANKS]: 'Fill in the Blanks',
    [TypologyEnum.COMBINATION]: 'Combination',
    [TypologyEnum.WRITING]: 'Writing',
  }

  return typologyTitle[typology]
}

const useNextLesson = (onFinish?: () => void) => {
  const { unitId, lessonId } = useParams()
  const { setActivityUnit } = useActivity()

  const [currentLesson, setCurrentLesson] = useState<SubModuleOnModule>()
  const [nextLesson, setNextLesson] = useState<SubModuleOnModule>()

  const storeLevel = getStoreLevel()
  const unit = JSON.parse(store('unit'))
  const lesson = JSON.parse(store('lesson'))

  const currentUnitId = unitId || unit.id
  const currentLessonId = lessonId || lesson.id

  const { loading: loadingLessons, refetch } = useQuery<ModuleLessonsData>(
    GET_MODULE,
    {
      variables: {
        id: currentUnitId,
      },
      onCompleted: (data) => {
        if (data?.FindModule) {
          const { FindModule } = data

          const lessonA = FindModule.subModules?.find(
            (subModule: SubModuleOnModule) =>
              subModule.subModule.id === currentLessonId
          )
          setActivityUnit(FindModule)
          setCurrentLesson(lessonA)

          const lessonB = FindModule.subModules?.find(
            (subModule: SubModuleOnModule) =>
              subModule?.order === lessonA?.order + 1
          )
          setNextLesson(lessonB)
        }
      },
    }
  )

  const [getUnits, { loading: loadingUnits }] =
    useLazyQuery<ModuleLessonsData>(GET_MODULE)

  const loading = loadingLessons || loadingUnits

  const goToNextLesson = async () => {
    if (nextLesson) {
      const {
        subModule: { id, title },
      } = nextLesson
      store('lesson', JSON.stringify({ id, title }))

      if (onFinish) onFinish()

      navigate(
        routes.learningActivityPage({
          lessonId: id,
          unitId: currentUnitId,
        })
      )
    } else {
      goToNextUnitFirstLesson()
    }
  }

  const goToNextUnitFirstLesson = async () => {
    const response = await getUnits({
      variables: {
        id: storeLevel?.id,
      },
    })

    const currentUnit = response?.data?.FindModule.subModules?.find(
      (subModule: SubModuleOnModule) => subModule.subModule.id === currentUnitId
    )
    const nextUnit = response?.data?.FindModule.subModules?.find(
      (subModule: SubModuleOnModule) =>
        subModule?.order === currentUnit?.order + 1
    )

    if (nextUnit) {
      const {
        subModule: { id: nextUnitId, title: nextUnitTitle },
      } = nextUnit

      const refetchResponse = await refetch({
        id: nextUnitId,
      })

      if (refetchResponse?.data?.FindModule) {
        const nextUnitLesson =
          refetchResponse?.data?.FindModule.subModules?.find(
            (subModule: SubModuleOnModule) => subModule.order === 0
          )
        const {
          subModule: { id: nextUnitLessonId, title: nextUnitLessonTitle },
        } = nextUnitLesson

        store('unit', JSON.stringify({ id: nextUnitId, title: nextUnitTitle }))
        store(
          'lesson',
          JSON.stringify({ id: nextUnitLessonId, title: nextUnitLessonTitle })
        )

        if (onFinish) onFinish()

        navigate(
          routes.learningActivityPage({
            lessonId: nextUnitLessonId,
            unitId: nextUnitId,
          })
        )
      }
    }
  }

  return { goToNextLesson, currentLesson, nextLesson, loading }
}

export { useRandomChoices, useActivityTitle, useNextLesson }
