import { AppShell, rem, em } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import { IconMessageCircle2, IconBook2 } from '@tabler/icons-react'

import FooterMenu from 'src/components/learning/FooterMenu/FooterMenu'
import Header from 'src/components/learning/Header/Header'
import ProfileMenu from 'src/components/learning/ProfileMenu'
import ContainerWrap from 'src/components/shared/ContainerWrap/ContainerWrap'
import { externalLinks } from 'src/helpers/externalLinks'
import { LevelProvider } from 'src/providers/learning/level/LevelProvider'

type LearningLayoutProps = {
  children?: React.ReactNode
}

export const menu = [
  {
    label: 'common.activities',
    route: '/learning/dashboard',
    icon: <IconBook2 stroke={1.75} size={32} />,
  },
  {
    label: 'common.online-teacher',
    route: externalLinks.onlineTeacher,
    icon: <IconMessageCircle2 stroke={1.75} size={32} />,
  },
]

const LearningLayout = ({ children }: LearningLayoutProps) => {
  const isSmallScreen = useMediaQuery(`(max-width: ${em(991)})`)

  return (
    <LevelProvider>
      <AppShell
        bg={isSmallScreen ? 'white' : 'var(--mantine-color-body)'}
        className="learning-page"
        footer={{ height: rem(100) }}
      >
        <AppShell.Main pb={isSmallScreen ? rem(120) : rem(20)} className="main">
          <Header menu={menu}>
            <ProfileMenu />
          </Header>
          <ContainerWrap>{children}</ContainerWrap>
        </AppShell.Main>
        <AppShell.Footer hiddenFrom="md" className="footer">
          <FooterMenu menu={menu} />
        </AppShell.Footer>
      </AppShell>
    </LevelProvider>
  )
}

export default LearningLayout
