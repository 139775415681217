import { Anchor, Box, Group, Paper, Text, Title, rem } from '@mantine/core'
import { IconRefresh, IconUsers } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { Link } from '@redwoodjs/router'

const ClassSwitcher = () => {
  const { t } = useTranslation('learning')

  return (
    <Anchor
      component={Link}
      to="/learning/classes"
      underline="never"
      data-sel="change-class"
    >
      <Group justify="space-between">
        <Group>
          <IconUsers size={20} color="black" />
          <Box>
            <Text c="dark.3" size={rem(12)}>
              {t('teacher.my-classes')}
            </Text>
            <Title order={6} c="black">
              01B - Vila Mariana
            </Title>
          </Box>
        </Group>
        <Paper withBorder px={rem(10)} py={rem(6)} radius="lg">
          <Group gap={rem(5)}>
            <Text c="black" size={rem(12)}>
              {t('common.change')}
            </Text>
            <IconRefresh size={12} color="black" />
          </Group>
        </Paper>
      </Group>
    </Anchor>
  )
}

export default ClassSwitcher
