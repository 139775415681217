import { AppShell } from '@mantine/core'
import { IconBook2, IconUsers } from '@tabler/icons-react'

import { routes } from '@redwoodjs/router'

import FooterMenu from 'src/components/learning/FooterMenu'
import Header from 'src/components/learning/Header/Header'
import TeacherProfileMenu from 'src/components/learning/TeacherProfileMenu'
import { useIsSmallScreen } from 'src/hooks/shared/screen'
type props = {
  children?: React.ReactNode
}

export const menu = [
  {
    label: 'teacher.my-classes',
    route: '/learning/classes',
    icon: <IconBook2 stroke={1.75} size={32} />,
  },
]

const footerMenu = [
  {
    label: 'teacher.classes',
    route: '/learning/classes',
    icon: <IconUsers size={20} />,
  },
]

const LearningTeacherLayout = ({ children }: props) => {
  const isSmallScreen = useIsSmallScreen()

  return (
    <AppShell bg={isSmallScreen ? 'white' : 'color-body'}>
      <AppShell.Main>
        <Header menu={menu} logoTo={routes.learningClassesPage()}>
          <TeacherProfileMenu />
        </Header>
        {children}
      </AppShell.Main>
      {isSmallScreen && (
        <AppShell.Footer>
          <FooterMenu menu={footerMenu} />
        </AppShell.Footer>
      )}
    </AppShell>
  )
}

export default LearningTeacherLayout
