import {
  Title,
  Group,
  Grid,
  Box,
  Text,
  Paper,
  Anchor,
  Avatar,
  Badge,
  rem,
} from '@mantine/core'
import { IconChevronRight, IconWriting } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'

import { Link, useParams } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import BackNavigation from 'src/components/learning/BackNavigation/BackNavigation'
import ContainerWrap from 'src/components/shared/ContainerWrap/ContainerWrap'
import { getInitials } from 'src/helpers/user'
import { useIsSmallScreen } from 'src/hooks/shared/screen'

import ClassSwitcher from './components/ClassSwitcher/ClassSwitcher'

const students = [
  {
    id: 1,
    name: 'Elis Araújo',
    level: 'Teen Up 1',
    activities: 3,
    picture: null,
  },
  {
    id: 2,
    name: 'Gabriela Conceição Sanches dos Santos',
    level: 'Teen Up 1',
    activities: 2,
    picture: null,
  },
  {
    id: 3,
    name: 'Julio Camargo',
    level: 'Teen Up 1',
    activities: 1,
    picture: null,
  },
  {
    id: 4,
    name: 'Lucia Barbosa',
    level: 'Teen Up 1',
    activities: 1,
    picture: null,
  },
  {
    id: 5,
    name: 'Xirley Santos',
    level: 'Teen Up 1',
    activities: 0,
    picture: null,
  },
]

const ClassStudentsPage = () => {
  const isSmallScreen = useIsSmallScreen()
  const { t } = useTranslation('learning')
  const { classId } = useParams()

  return (
    <>
      <Metadata title="Students" description="Class Students page" />
      <ContainerWrap>
        <Grid pb={rem(50)} data-sel="class-students">
          {!isSmallScreen && (
            <Grid.Col span={{ md: 4, lg: 3 }} m={0}>
              <Paper withBorder radius="md" p={rem(24)}>
                <ClassSwitcher />
              </Paper>
            </Grid.Col>
          )}
          <Grid.Col span={{ xs: 12, md: 8, lg: 9 }}>
            <BackNavigation backTo="/learning/classes" label="common.back" />
            <Title order={4} pt={rem(20)} data-sel="class-students-title">
              {t('teacher.my-students')}
            </Title>
            <Box mt={rem(30)}>
              {isSmallScreen && (
                <Box pb={rem(40)}>
                  <ClassSwitcher />
                </Box>
              )}
            </Box>
            {students.map((student) => (
              <Box key={student.id} pb={rem(40)}>
                <Anchor
                  component={Link}
                  to={`/learning/classes/${classId}/student/${student.id}`}
                  data-sel="student-link"
                  underline="never"
                  c="var(--text-color)"
                >
                  <Group justify="space-between">
                    <Group>
                      <Avatar
                        data-sel="student-avatar"
                        src={student.picture || null}
                        alt="Student picture"
                        size={40}
                        bg={student.picture ? 'transparent' : 'cnaRed.6'}
                        styles={{
                          root: {
                            '--avatar-color': 'white',
                          },
                          placeholder: {
                            fontWeight: 500,
                          },
                        }}
                      >
                        {!student.picture ? getInitials(student.name) : ''}
                      </Avatar>
                      <Box>
                        <Text
                          c="black"
                          size={rem(14)}
                          data-sel="student-name"
                          truncate="end"
                          maw="calc(100vw - 200px)"
                        >
                          {student.name}
                        </Text>
                        <Text
                          c="dark.3"
                          size={rem(12)}
                          pt={rem(8)}
                          data-sel="student-level"
                        >
                          {student.level}
                        </Text>
                      </Box>
                    </Group>
                    <Group>
                      {student.activities && (
                        <Badge
                          data-sel="activity-count"
                          bg="cnaRed.0"
                          px={rem(12)}
                          py={rem(18)}
                        >
                          <Group gap={rem(4)}>
                            <IconWriting
                              size={16}
                              color="var(--mantine-color-cnaRed-7)"
                            />
                            <Text c="cnaRed.7" size={rem(12)}>
                              {student.activities}
                            </Text>
                          </Group>
                        </Badge>
                      )}
                      <IconChevronRight size={24} />
                    </Group>
                  </Group>
                </Anchor>
              </Box>
            ))}
          </Grid.Col>
        </Grid>
      </ContainerWrap>
    </>
  )
}

export default ClassStudentsPage
